import { graphql } from "gatsby"
import { withPreview } from "gatsby-source-prismic"
import React from "react"
import Carousel from "../components/carousel"
import FeaturedPostsSection from "../components/featuredPostsSection"
import SEO from "../components/seo"

const BlogIndex = ({ data, location }) => {
  const content = data.homepage?.data
  const seoTitle = content?.meta_title
  const seoDescription = content?.meta_description
  const carousel = content?.carousel
  const featuredPosts = content?.body

  return (
    <>
      <SEO title={seoTitle} description={seoDescription} />
      <Carousel items={carousel} />
      <div className="container">
        {featuredPosts?.map(section => {
          return (
            <FeaturedPostsSection
              section={section}
              key={section.primary.title_of_section.text}
            />
          )
        })}
      </div>
    </>
  )
}

export default withPreview(BlogIndex)

export const pageQuery = graphql`
  query HomePage($locale: String!) {
    homepage: prismicHome(lang: { eq: $locale }) {
      data {
        meta_title
        meta_description
        title {
          text
        }
        carousel {
          post {
            link_type
            slug
            uid
            document {
              ... on PrismicPost {
                url
                data {
                  title {
                    text
                  }
                  featured_image {
                    url
                    thumbnails {
                      slider {
                        fluid(maxHeight: 800) {
                          ...GatsbyPrismicImageFluid
                        }
                      }
                    }
                    fluid(maxHeight: 800) {
                      ...GatsbyPrismicImageFluid
                    }
                  }
                }
              }
            }
          }
        }
        body {
          ... on PrismicHomeBodyListOfArticles {
            primary {
              title_of_section {
                text
              }
            }
            items {
              articles_to_link {
                id
                uid
                document {
                  ... on PrismicPost {
                    url
                    data {
                      title {
                        text
                      }
                      featured_image {
                        fluid(maxHeight: 300) {
                          ...GatsbyPrismicImageFluid
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
