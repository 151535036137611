/**
 * Carousel component
 */

import { Link } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import { Carousel as RBCarousel } from "react-bootstrap"

const Carousel = ({ items }) => {
  return (
    <>
      <RBCarousel interval={null}>
        {items?.map(item => {
          console.log(item.post);
          return (
            <RBCarousel.Item key={item.post.document.data.title.text}>
              <Img
                fluid={
                  item.post.document.data.featured_image.thumbnails.slider.fluid
                }
              />

              <RBCarousel.Caption>
                <h1>
                  <span>
                    <Link to={item.post.document.url} itemProp="url">
                      {item.post.document.data.title.text}
                    </Link>
                  </span>
                </h1>
              </RBCarousel.Caption>
            </RBCarousel.Item>
          )
        })}
      </RBCarousel>
    </>
  )
}

export default Carousel
