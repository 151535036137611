/**
 * Featured posts section component
 */

import React from "react"
import FeaturedPost from "./featuredPost"

const FeaturedPostsSection = ({ section }) => {
  return (
    <section>
      <h2>{section.primary.title_of_section.text}</h2>
      <div className="row">
        {section.items.map(item => {
          return (
            <div className="col-sm-12 col-md" key={item.articles_to_link.uid}>
              <FeaturedPost post={item.articles_to_link.document} />
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default FeaturedPostsSection
